var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "table-actions p-3 d-flex"
  }, [_c('input', {
    staticClass: "b-input-search mr-3",
    attrs: {
      "type": "text",
      "placeholder": "Найти"
    }
  }), _c('div', {
    staticClass: "toggle-filter mr-3"
  }, [_c('label', {
    attrs: {
      "for": "check_statistic"
    }
  }, [_vm._v("Статистика по чекам")]), _c('b-form-checkbox', {
    attrs: {
      "id": "check_statistic",
      "button-variant": "secondary",
      "switch": ""
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "light"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/btn-settings.svg",
      "width": "12",
      "alt": ""
    }
  })])], 1), _c('b-table', {
    ref: "table_products",
    staticClass: "table-products",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.products,
      "busy": _vm.tableIsBusy,
      "empty-filtered-text": "Нет товаров",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }