<template>
  <b-card
    no-body
    class="p-0"
  >
    <div class="table-actions p-3 d-flex">
      <input
        type="text"
        class="b-input-search mr-3"
        placeholder="Найти"
      />
      <div class="toggle-filter mr-3">
        <label for="check_statistic">Статистика по чекам</label>
        <b-form-checkbox
          id="check_statistic"
          button-variant="secondary"
          switch
        />
      </div>
      <b-button variant="light">
        <img
          src="/img/icons/btn-settings.svg"
          width="12"
          alt=""
        />
      </b-button>
    </div>
    <b-table
      ref="table_products"
      :fields="fields"
      :items="products"
      :busy="tableIsBusy"
      empty-filtered-text="Нет товаров"
      show-empty
      class="table-products"
    >
      <template #table-busy>
        <div class="text-center">
          <b-spinner
            variant="primary"
            label="Загрузка..."
          />
          <div><strong>Загрузка...</strong></div>
        </div>
      </template>

      <template #head(id)>
        <b-form-checkbox v-model="select_all" />
      </template>

      <template #empty="scope">
        <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
      </template>
    </b-table>
  </b-card>
</template>

<script>
  export default {
    data: () => ({
      products: [],
      tableIsBusy: false,
      select_all: false,
      selected: []
    }),

    computed: {
      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'w-5 center'
          },
          {
            key: 'num',
            label: '№',
            class: 'w-5',
            sortable: true
          },
          {
            key: 'name',
            label: 'Наименование товара',
            sortable: true
          },
          {
            key: 'remains',
            label: 'Остаток',
            sortable: true
          },
          {
            key: 'count',
            label: 'Общее количество',
            sortable: true
          },
          {
            key: 'price',
            label: 'Цена',
            sortable: true
          },
          {
            key: 'sum',
            label: 'Сумма'
          }
        ]
      }
    },

    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-products {
    margin-bottom: -1px;
  }
  .b-input-search {
    max-width: 100%;
    width: 268px;
  }
  .toggle-filter {
    display: flex;
    align-items: center;
    gap: 12px;
    background: #f9f9f9;
    padding: 4px 6px 4px 12px;

    label {
      margin: 0;
      color: #888;
    }
  }
</style>
