<template>
  <section id="new-document">
    <breadcrumbs class="breadcrumbs" />
    <b-card class="p-0 border-0">
      <b-button
        v-b-toggle.collapse-params
        class="collapse-link"
      >
        Параметры документа
      </b-button>
      <b-collapse
        id="collapse-params"
        visible
      >
        <div class="params-body">
          <b-form class="params-form">
            <b-form-group
              label="Номер документа"
              label-for="document_num"
            >
              <i-select id="document_num">
                <i-option value="1">Номер 1</i-option>
                <i-option value="2">Номер 2</i-option>
              </i-select>
            </b-form-group>
            <b-form-group
              label="Дата документа"
              label-for="document_date"
            >
              <date-picker
                id="document_date"
                class="elm-calendar"
                placeholder="Выберите дату"
                :clearable="false"
              />
            </b-form-group>
            <b-form-group
              label="Место хранения"
              label-for="save_place"
            >
              <b-input-group class="select-with-button">
                <i-select id="save_place">
                  <i-option value="1">Место хранения 1</i-option>
                  <i-option value="2">Место хранения 2</i-option>
                </i-select>
                <b-input-group-append>
                  <b-dropdown
                    no-caret
                    variant="none"
                  >
                    <template #button-content>
                      <div class="btn btn-white btn-more">
                        <img
                          src="/img/icons/more.svg"
                          alt=""
                          width="12"
                          height="12"
                        />
                      </div>
                    </template>
                    <b-dropdown-item>Тест 1</b-dropdown-item>
                    <b-dropdown-item>Тест 2</b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Операция"
              label-for="operation"
            >
              <b-form-input id="operation" />
            </b-form-group>
          </b-form>
          <div class="params-form-actions">
            <b-button
              variant="secondary"
              class="show-btn justify-content-center"
            >
              Показать
            </b-button>
          </div>
        </div>
      </b-collapse>
    </b-card>

    <products-table class="mt-3" />
    <cash-checks-table class="mt-3" />
  </section>
</template>

<script>
  import Breadcrumbs from '@breadcrumbs'
  import ProductsTable from '../components/ProductsTable.vue'
  import CashChecksTable from '../components/CashChecksTable.vue'

  export default {
    components: {
      Breadcrumbs,
      ProductsTable,
      CashChecksTable
    }
  }
</script>

<style lang="scss" scoped>
  #new-document {
    .collapse-link {
      background: none !important;
      border: none !important;
      padding: 0 22px 0 0 !important;
      position: relative;
      color: #313131;
      font-size: 20px;
      font-weight: 500;

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: calc(50% + 2px);
        transform: translateY(-50%) rotate(-90deg);
        width: 12px;
        height: 12px;
        background: url('/img/icons/contractors/arrow_down.svg') no-repeat 0 0;
        background-size: contain;
      }
      &.not-collapsed::after {
        transform: translateY(-50%);
      }
    }
    .params-form {
      display: flex;
      flex-wrap: wrap;
      gap: 15px 16px;

      ::v-deep .form-group {
        min-width: 188px;
      }
    }
    .params-body {
      padding-top: 30px;
    }
    .show-btn {
      width: 300px;
      max-width: 100%;
    }
  }
</style>
