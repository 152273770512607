<template>
  <b-card
    no-body
    class="p-0"
  >
    <div class="table-actions p-3 d-flex">
      <b-button variant="light">
        <img
          src="/img/icons/btn-settings.svg"
          width="12"
          alt=""
        />
      </b-button>
    </div>
    <b-table
      ref="table_additional"
      :fields="fields"
      :items="items"
      :busy="tableIsBusy"
      empty-filtered-text="Нет чеков"
      show-empty
      class="table-additional"
    >
      <template #table-busy>
        <div class="text-center">
          <b-spinner
            variant="primary"
            label="Загрузка..."
          />
          <div><strong>Загрузка...</strong></div>
        </div>
      </template>

      <template #empty="scope">
        <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
      </template>
    </b-table>
  </b-card>
</template>

<script>
  export default {
    data: () => ({
      items: [],
      tableIsBusy: false
    }),

    computed: {
      fields: function () {
        return [
          {
            key: 'num_kassa',
            label: 'Номер кассы'
          },
          {
            key: 'num_z',
            label: 'Номер Z отчета'
          },
          {
            key: 'cash_check',
            label: 'Чек'
          },
          {
            key: 'date',
            label: 'Время'
          },
          {
            key: 'position',
            label: 'Позиция в чеке'
          },
          {
            key: 'cashier',
            label: 'Кассир'
          },
          {
            key: 'consultant',
            label: 'Консультант'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-additional {
    margin-bottom: -1px;
  }
</style>
