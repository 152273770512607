var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "new-document"
    }
  }, [_c('breadcrumbs', {
    staticClass: "breadcrumbs"
  }), _c('b-card', {
    staticClass: "p-0 border-0"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-params",
      modifiers: {
        "collapse-params": true
      }
    }],
    staticClass: "collapse-link"
  }, [_vm._v(" Параметры документа ")]), _c('b-collapse', {
    attrs: {
      "id": "collapse-params",
      "visible": ""
    }
  }, [_c('div', {
    staticClass: "params-body"
  }, [_c('b-form', {
    staticClass: "params-form"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Номер документа",
      "label-for": "document_num"
    }
  }, [_c('i-select', {
    attrs: {
      "id": "document_num"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Номер 1")]), _c('i-option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Номер 2")])], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Дата документа",
      "label-for": "document_date"
    }
  }, [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "document_date",
      "placeholder": "Выберите дату",
      "clearable": false
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Место хранения",
      "label-for": "save_place"
    }
  }, [_c('b-input-group', {
    staticClass: "select-with-button"
  }, [_c('i-select', {
    attrs: {
      "id": "save_place"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Место хранения 1")]), _c('i-option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Место хранения 2")])], 1), _c('b-input-group-append', [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn btn-white btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/more.svg",
            "alt": "",
            "width": "12",
            "height": "12"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("Тест 1")]), _c('b-dropdown-item', [_vm._v("Тест 2")])], 1)], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Операция",
      "label-for": "operation"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "operation"
    }
  })], 1)], 1), _c('div', {
    staticClass: "params-form-actions"
  }, [_c('b-button', {
    staticClass: "show-btn justify-content-center",
    attrs: {
      "variant": "secondary"
    }
  }, [_vm._v(" Показать ")])], 1)], 1)])], 1), _c('products-table', {
    staticClass: "mt-3"
  }), _c('cash-checks-table', {
    staticClass: "mt-3"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }